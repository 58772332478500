const routes = [
  {
    equals: '/',
    app: 'shipmentPlanner',
    name:'instaRatesIntroPage',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/login',
    name: 'loginRoutingApp',
    app: 'login',
    customProps: {},
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/signup', '/update-password'],
    app: 'authV2',
    hideHeader: true,
    customProps: {},
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: ['/forgot-password', '/change-password'],
    app: 'changePassword',
    hideHeader: true,
    hideFooter: true,
    hideSideNav: true,
  },
  {
    startsWith: '/account',
    app: 'customers',
    isProtected: true,
  },
  {
    startsWith: ['/enquiries', '/enquiriesV2'],
    app: 'enquiriesV2',
    isProtected: true,
  },
  {
    startsWith: '/planner',
    app: 'shipmentPlanner',
    isProtected: true,
  }
];

export default routes;